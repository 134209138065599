<template>
    <div class="construction_matching" v-loading="isLoading">
        <el-container>
            <el-container>
                <el-header class="header_wrapper">
                    <h4 class="head">
                        生产任务信息&nbsp;&nbsp;&nbsp;<strong>({{ task_number }})</strong>
                    </h4>
                    <div class="save" @click="saveProductFormula">
                        保存
                    </div>
                    <table>
                        <tbody>
                            <tr>
                                <th>工程名称：</th>
                                <td>
                                    <el-input disabled v-model="productFormula.project_name" placeholder="请输入内容"></el-input>
                                </td>
                                <th>工程部位:</th>
                                <td>
                                    <el-input disabled v-model="productFormula.place_detail" placeholder="请输入内容"></el-input>
                                </td>
                                <th>强度等级:</th>
                                <td>
                                    <!-- <el-select
                                        v-model="productformula.strength_grade"
                                        placeholder="请选择"
                                    >
                                        <el-option
                                            v-for="item in selectData.qddj.options"
                                            :key="item.code"
                                            :label="item.name"
                                            :value="item.code"
                                        ></el-option>
                                    </el-select> -->
                                    <el-input disabled v-model="productFormula.strength_grade_name" placeholder="请输入内容"></el-input>
                                    <input type="hidden" name="" v-model="productFormula.strength_grade">
                                </td>
                            </tr>
                            <tr>
                                <th>坍落度:</th>
                                <td>
                                    <el-input disabled v-model="productFormula.slump" placeholder="请输入内容"></el-input>
                                </td>
                                <th>浇筑方式:</th>
                                <td>
                                    <el-input disabled v-model="productFormula.pouring_name" placeholder="请输入内容"></el-input>
                                    <input type="hidden" name="" v-model="productFormula.pouring">
                                </td>
                                <th>是否出资料：</th>
                                <td>
                                    <el-radio-group v-model="productFormula.is_datum">
                                        <el-radio :label="true">
                                            是
                                        </el-radio>
                                        <el-radio :label="false">
                                            否
                                        </el-radio>
                                    </el-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <th>特殊要求:</th>
                                <td>
                                    <!-- <el-select
                                        v-model="productFormula.special_require"
                                        placeholder="请选择"
                                    >
                                        <el-option
                                            v-for="item in selectData.tsyq.options"
                                            :key="item.code"
                                            :label="item.name"
                                            :value="item.code"
                                        ></el-option>
                                    </el-select> -->
                                    <el-input disabled v-model="productFormula.special_require_name" placeholder="请输入内容"></el-input>
                                    <input type="hidden" name="" v-model="productFormula.special_require">
                                </td>
                                <th>产生抗压试验：</th>
                                <td>
                                    <el-radio-group v-model="productFormula.is_testblock">
                                        <el-radio :label="true">
                                            是
                                        </el-radio>
                                        <el-radio :label="false">
                                            否
                                        </el-radio>
                                    </el-radio-group>
                                </td>
                                <th>试块组数:</th>
                                <td>
                                    <el-input v-model.number="productFormula.block_group" placeholder="请输入内容"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>备注:</th>
                                <td colspan="5">
                                    <el-input
                                        placeholder="备注"
                                        disabled
                                        v-model="productFormula.remarks"
                                        style="width: 100%"
                                    >
                                    </el-input>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </el-header>
                <el-main>
                    <h4 class="head">
                        关联配比
                    </h4>
                    <div class="production_wrapper">
                        <span class="production_span">生产机组：</span>
                        <div class="radio_list">
                            <el-radio-group v-model="mixstationChecked" @change="mixstationChange">
                                <el-radio
                                    v-for="item in mixstationlineData"
                                    :key="item.line_code"
                                    :label="item.line_code"
                                    border
                                    :class="lineClass(item.is_adjust_stock)"
                                >
                                    {{ item.line_name }}
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div class="main_header">
                        <div class="matching_type_wrapper">
                            <span>配比类型：</span>
                            <div class="radio_list">
                                <el-radio-group v-model="formulaTypeChecked" @change="formulaTypeChange">
                                    <el-radio
                                        v-for="item in formulaTypeData"
                                        :label="item.value"
                                        :key="item.value"
                                    >
                                        {{ item.label }}
                                    </el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="btn_wrapper">
                            <el-button type="primary" @click="theorySelect">
                                理论配比
                            </el-button>
                            <el-button type="primary" @click="Choice">
                                试验配比
                            </el-button>
                            <el-button type="primary" :disabled="!mixstationChecked && formulaTypeChecked !== null" @click="saveLineSync">
                                同步
                            </el-button>
                            <el-button
                                type="primary"
                                :disabled="!mixstationChecked && formulaTypeChecked !== null"
                                @click="saveLine"
                            >
                                保存
                            </el-button>
                        </div>
                    </div>
                    <div class="content_left">
                        <el-table
                            :data="tableData"
                            border
                            width="100%"
                            height="3.2rem"
                            :header-cell-style="{ height: '0.34rem', background: 'rgba(237,240,245,1)', color: '#022782', padding: '0'}"
                            :cell-style="cellStyle"
                        >
                            <el-table-column prop="plcw" label="工控编号"></el-table-column>
                            <el-table-column label="工控料仓类型">
                                <template slot-scope="scope">
                                    <span @click="JumpChange(scope.row)">{{ scope.row.plcwname }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="原材料名称">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.stock_category_name }}</span>
                                    <input type="hidden" name v-model="scope.row.stock_category">
                                </template>
                            </el-table-column>
                            <el-table-column label="原材料子类">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.stock_type === 'F3-01-WL'">{{ scope.row.material_child_name }}</span>
                                    <div v-if="scope.row.stock_type === 'F3-01-XN'">
                                        <div v-if="scope.row.stock_category_dictcode === '203'">
                                            <!-- <div
                                            @click="materialChildSelect(scope.$index,scope.row.stock_code,scope.row.stock_category_dictcode)">
                                                {{ scope.row.material_child_name }}<i class="el-icon-edit"></i>
                                            </div> -->
                                            <div>{{ scope.row.material_child_name }}</div>
                                        </div>
                                        <div v-else>
                                            <div>{{ scope.row.material_child_name }}</div>
                                        </div>
                                    </div>
                                    <input type="hidden" name v-model="scope.row.material_child_dictcode">
                                </template>
                            </el-table-column>
                            <el-table-column label="规格" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.stock_type === 'F3-01-WL'">{{ scope.row.material_name }}</span>
                                    <div v-if="scope.row.stock_type === 'F3-01-XN'">
                                        <div v-if="scope.row.stock_category_dictcode === '203'" style="cursor: pointer;">
                                            <!-- <div @click="materialModelSelect(scope.$index,scope.row.material_child_dictcode)">
                                                {{ scope.row.material_name }}<i class="el-icon-edit"></i>
                                            </div> -->
                                            <div @click="materialChildSelect(scope.$index,scope.row.stock_code,scope.row.stock_category_dictcode)">
                                                {{ scope.row.material_name }}
                                                <i class="el-icon-edit" :class="{ 'active-red': !scope.row.material_code }"></i>
                                            </div>
                                        </div>
                                        <div v-else-if="scope.row.stock_category_dictcode === '204'" style="cursor: pointer;">
                                            <div @click="materialChildSelect(scope.$index,scope.row.stock_code,scope.row.stock_category_dictcode)">
                                                {{ scope.row.material_name }}
                                                <i class="el-icon-edit" :class="{ 'active-red': !scope.row.material_code }"></i>
                                            </div>
                                        </div>
                                        <div v-else>
                                            {{ scope.row.material_name }}
                                        </div>
                                    </div>
                                    <input type="hidden" name v-model="scope.row.material_code">
                                </template>
                            </el-table-column>
                            <el-table-column label="实验用量">
                                <template slot-scope="scope">
                                    {{ scope.row.experiment_quantity }}
                                    <!-- <el-input v-model="scope.row.experiment_quantity" placeholder="请输入"></el-input> -->
                                </template>
                            </el-table-column>
                            <el-table-column label="施工用量">
                                <template slot-scope="scope">
                                    <!-- {{ scope.row.product_quantity }} -->
                                    <el-input
                                        v-model="scope.row.product_quantity"
                                        @input="productQuantityChange"
                                        @mousewheel.native.prevent
                                        min="0"
                                        type="number"
                                        placeholder="请输入"
                                    ></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="含水率">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.stock_category_dictcode === '203' || scope.row.stock_category_dictcode === '204'">
                                        <el-input
                                            v-model="scope.row.moisture_ratio"
                                            @mousewheel.native.prevent
                                            min="0"
                                            type="number"
                                            placeholder="请输入"
                                        ></el-input>
                                    </div>
                                    <span v-else>{{ scope.row.moisture_ratio }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="差值">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.stock_category_dictcode === '203' || scope.row.stock_category_dictcode === '204'">
                                        <el-input
                                            v-model="scope.row.moisture_ratio_difference"
                                            @mousewheel.native.prevent
                                            min="0"
                                            type="number"
                                            placeholder="请输入"
                                        ></el-input>
                                    </div>
                                    <span v-else>{{ scope.row.moisture_ratio_difference }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="含石率">
                                <template slot-scope="scope">
                                    <el-input
                                        v-if="scope.row.stock_category_dictcode === '203'"
                                        v-model="scope.row.stone_rate"
                                        @mousewheel.native.prevent
                                        placeholder="请输入"
                                        min="0"
                                        type="number"
                                    >
                                    </el-input>
                                    <span v-else>{{ scope.row.stone_rate }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="差值">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.stock_category_dictcode === '203'">
                                        <el-input
                                            v-model="scope.row.stone_rate_difference"
                                            @mousewheel.native.prevent
                                            min="0"
                                            type="number"
                                            placeholder="请输入"
                                        ></el-input>
                                    </div>
                                    <span v-else>{{ scope.row.stone_rate_difference }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-main>
            </el-container>
            <el-aside width="200px">
                <h4 class="head">
                    发送到机组
                </h4>
                <div class="send_out" @click="sendOut">
                    发送
                </div>
                <div>
                    <el-table
                        :data="mixstationlineData"
                        border
                        style="width: 100%;font-size:0.14rem;"
                        :header-cell-style="{ height: '0.34rem', background: 'rgba(237,240,245,1)', color: '#022782', padding: '0'}"
                    >
                        <el-table-column
                            prop="line_name"
                            label="生产机组"
                        >
                        </el-table-column>
                        <el-table-column
                            label="砼配比编号"
                            prop="product_formula_number_hjt"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            label="润泵砂浆编号"
                            prop="product_formula_number_sj"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            label="是否关联"
                        >
                            <template slot-scope="scope">
                                <span v-if=" scope.row.product_formula_number_hjt">
                                    <i class="iconfont iconxuanzhong" style="color:#1577D2;font-weight:800;font-size:22px"></i>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="是否发送"
                        >
                            <template slot-scope="scope">
                                <div v-if="scope.row.product_formula_number_hjt === null">
                                </div>
                                <div v-else>
                                    <span v-if="scope.row.sync_status">
                                        <i class="iconfont iconxuanzhong" style="color:#1577D2;font-weight:800;font-size:22px"></i>
                                    </span>
                                    <span v-else>
                                        <input
                                            class="sync_status"
                                            type="checkbox"
                                            :id="scope.$index"
                                            :value="scope.row.line_code"
                                            v-model="syncStatus"
                                        ><label :for="scope.$index">
                                            <i class="iconfont icondanxuanweixuan" style="font-weight:800;font-size:22px;"></i>
                                        </label>
                                    </span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="content_rigth">
                    <ul>
                        <li class="sp1">
                            <span>设计容重</span>
                            <p>{{ productFormulaCount.design_volume_weight }}<i>kg</i></p>
                        </li>
                        <li class="sp5">
                            <span>实际容重</span>
                            <p>{{ productFormulaCount.practical_volume_weight }}<i>kg</i></p>
                        </li>
                        <li class="sp2">
                            <span>坍落度</span>
                            <p>{{ productFormulaCount.slump }}<i>mm</i></p>
                        </li>
                        <li class="sp3">
                            <span>水胶比</span>
                            <p>{{ productFormulaCount.water_binder_ratio }}<i>%</i></p>
                        </li>
                        <li class="sp4">
                            <span>砂率</span>
                            <p>{{ productFormulaCount.sand_rate }}<i>%</i></p>
                        </li>
                        <li class="sp5">
                            <span>最大粒径</span>
                            <p>{{ productFormulaCount.max_particle_size }}<i>mm</i></p>
                        </li>
                        <li class="sp3">
                            <span>细度模数</span>
                            <p>{{ productFormulaCount.fineness_modulus }}</p>
                        </li>
                    </ul>
                </div>
            </el-aside>
        </el-container>
        <el-dialog
            :visible.sync="dialogVisible1"
            width="40%"
            :modal="false"
            :close-on-click-modal="false"
            center
            @close="closeDialogChange"
        >
            <el-table
                :data="materialChildSelectData"
                border
                height="3rem"
                style="width: 100%;"
                :header-cell-style="{ height: '0.34rem', background: 'rgba(237,240,245,1)', color: '#022782', padding: '0'}"
            >
                <el-table-column label="" width="55">
                    <template slot-scope="scope">
                        <input
                            type="radio"
                            name="name"
                            v-model="materialChildValue"
                            :value="scope.row.material_name"
                        >
                    </template>
                </el-table-column>
                <el-table-column prop="material_name" label="名称"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible1 = false">取 消</el-button>
                <el-button type="primary" @click="materialChildSelectChange">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            :visible.sync="dialogVisible2"
            width="40%"
            :modal="false"
            :close-on-click-modal="false"
            center
            @close="closeDialogChange"
        >
            <el-table
                :data="materialModelSelectData"
                border
                height="3rem"
                style="width: 100%;"
                :header-cell-style="{ height: '0.34rem', background: 'rgba(237,240,245,1)', color: '#022782', padding: '0'}"
            >
                <el-table-column label="" width="55">
                    <template slot-scope="scope">
                        <input
                            type="radio"
                            name="name"
                            v-model="materialModelValue"
                            :value="scope.row.name"
                        >
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="名称"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="materialModelSelectChange">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            :visible.sync="dialogVisible3"
            width="40%"
            :modal="false"
            :close-on-click-modal="false"
            center
            @close="closeDialogChange"
        >
            <el-table
                :data="mixstationlineData"
                border
                height="3rem"
                style="width: 100%;"
                :header-cell-style="{ height: '0.34rem', background: 'rgba(237,240,245,1)', color: '#022782', padding: '0'}"
                @selection-change="handleSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="55"
                    :selectable="checkSelectable"
                >
                </el-table-column>
                <el-table-column prop="line_name" label="生产机组"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible3 = false">取 消</el-button>
                <el-button type="primary" @click="determineSync">确 定</el-button>
            </span>
        </el-dialog>
        <div class="maske" v-show="isShow"></div>
        <div class="dialog-wrapper" v-show="isShow">
            <div class="dialog-header">
                <h4>选择理论配比</h4>
                <span class="iconfont iconguanbi" @click="isShow=false"></span>
            </div>
            <div class="dialog-box">
                <div class="btn-wrapper">
                    <button @click="confirmSelectChange">
                        确认选择
                    </button>
                </div>
                <div class="table-wrapper table-bg">
                    <el-table
                        :data="theoryFormula"
                        style="width: 100%"
                        height="7rem"
                        @row-click="rowClickChange"
                    >
                        <el-table-column
                            label="选择"
                        >
                            <template slot-scope="scope">
                                <el-radio v-model="theoryChecked" :label="scope.row.theory_formula_number"></el-radio>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="theory_formula_number"
                            label="配比编号"
                            width="200"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="theory_formula_name"
                            label="配比名称"
                            width="200"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="strength_grade_name"
                            label="强度等级"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="impervious_name"
                            label="抗渗等级"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="antifreeze_name"
                            label="抗冻等级"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="flexural_name"
                            label="抗折要求"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="special_require_name"
                            label="特殊要求"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="design_volume_weight"
                            label="设计容重"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="sand_rate"
                            label="砂率"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="water_binder_ratio"
                            label="水胶比"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="water_cement_ratio"
                            label="水灰比"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="max_particle_size"
                            label="最大径粒"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="fineness_modulus"
                            label="细度模数"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="specification"
                            label="技术要求"
                        >
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        extr: {
            type: [Array, Object, String],
        },
    },
    data() {
        return {
            // 施工配比基本信息存储
            productFormula: {
                project_name: '',
                project_detail: '',
                is_datum: false,
                is_testblock: false,
            },
            // 强度等级、特殊要求options
            selectData: {
                // 强度等级
                qddj: {
                    checked: false,
                    options: [],
                },
                // 特殊要求
                tsyq: {
                    checked: false,
                    options: [],
                },
            },
            // 全部生产机组
            mixstationlineData: [],
            // 机组选中项code
            mixstationChecked: null,
            // 全部配比类型
            formulaTypeData: [
                {
                    label: '砼配比',
                    value: 0,
                },
                {
                    label: '润泵砂浆配比',
                    value: 1,
                },
            ],
            // 配比类型选中项
            formulaTypeChecked: 0,
            // 原材料子料控制弹框显示隐藏
            dialogVisible1: false,
            // 原材料规格控制弹框显示隐藏
            dialogVisible2: false,
            // 同步机组弹框显示隐藏
            dialogVisible3: false,
            // 原材料子料下拉列表数据
            materialChildSelectData: [],
            // 原材料规格下拉列表数据
            materialModelSelectData: [],
            // 当前操作列表index
            tableIndex: '',
            // 原材料子料弹框选中值
            materialChildValue: '',
            // 原材料规格弹框选中值
            materialModelValue: '',
            tableData: [],
            // 线路配比数据
            productFormulaLine: {},
            // 色块字段取值
            productFormulaCount: {},
            // 同步选中机组
            multipleSelection: [],
            // 需要发送的机组
            syncStatus: [],
            // 任务单号
            task_number: '',
            Table: '',
            isShow: false, // 理论配比弹框控制显示隐藏
            theoryFormula: [], // 理论配比基本信息
            theoryFormulaEntity: [], // 理论配比table数据
            theoryChecked: '', // 选择的理论配比
            theoryFormulaAll: [],
            isLoading: false,
        };
    },
    created() {
        this.task_number = this.extr.taskNumber;
        this.Table = this.extr._table;
    },
    mounted() {
        // 初始化页面数据
        this.getInit();
    },
    methods: {
        lineClass(val) {
            if (val) {
                return 'red-class';
            }
            return '';
        },
        // 理论配比点击行，选中
        rowClickChange(row) {
            this.theoryChecked = row.theory_formula_number;
        },
        // 点击确认选择理论配比
        confirmSelectChange() {
            if (this.theoryChecked) {
                this.isShow = false;

                const theoryFormulaEntity = this.theoryFormulaAll.filter(item => {
                    return item.theoryFormula.theory_formula_number === this.theoryChecked;
                })[0];
                this.productFormulaCount = theoryFormulaEntity.theoryFormula;
                this.tableData = theoryFormulaEntity.theoryFormulaEntity;
                this.productQuantityChange();

            } else {
                this.$message.error('请选择理论配比数据');
            }
        },
        theorySelect() {
            // 打开理论配比弹框、查询理论配比list
            // this.isShow = true;
            this.theoryList();
        },
        theoryList() {
            this.$axios
                .get(
                    '/interfaceApi/production/theoryformula/choose_formula'
                    + `/${this.mixstationChecked}/${this.productFormula.strength_grade}/${this.formulaTypeChecked}`
                )
                .then(res => {
                    this.theoryFormulaAll = res;
                    if (this.theoryFormulaAll.length <= 0) {
                        this.$message.error('未匹配到理论配比');
                    } else if (this.theoryFormulaAll.length === 1) {
                        this.productFormulaCount = this.theoryFormulaAll[0].theoryFormula;
                        this.tableData = this.theoryFormulaAll[0].theoryFormulaEntity;
                        this.productQuantityChange();
                        this.$message.success(`已加载理论配比【${this.theoryFormulaAll[0].theoryFormula.theory_formula_name}】`);
                    } else {
                        this.isShow = true;
                        const theoryFormula = [];
                        this.theoryFormulaAll.forEach(item => {
                            theoryFormula.push(item.theoryFormula);
                        });
                        this.theoryFormula = theoryFormula;
                    }

                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        productQuantityChange() {
            let total = 0;
            this.tableData.forEach(item => {
                total += Number(item.product_quantity ? item.product_quantity : 0);
            });
            this.$set(this.productFormulaCount, 'practical_volume_weight', total.toFixed(2));
        },
        JumpChange(row) {
            this.$toast(
                {
                    title: true,
                    text: '工控与ERP关系配置',
                    type: 'eject',
                    width: '15rem',
                    t_url: 'purchaseManage/centralControlConfig',
                    extr: {
                        mixstation_code: this.$takeTokenParameters('StationId'),
                        line_code: this.mixstationChecked,
                        plcw: row.plcw,
                        scene: 'view',
                    },
                }
            );
        },
        // 弹窗关闭的回调方法
        closeDialogChange() {
            // 原材料子料下拉列表数据
            this.materialChildSelectData = [];
            // 原材料规格下拉列表数据
            this.materialModelSelectData = [];
            // 原材料子料弹框选中值
            this.materialChildValue = '';
            // 原材料规格弹框选中值
            this.materialModelValue = '';
            // 同步选中机组
            this.multipleSelection = [];
        },
        // 发送
        sendOut() {
            this.isLoading = true;
            this.$axios
                .post('/interfaceApi/production/productformula/send',
                    {
                        task_number: this.extr.taskNumber,
                        send_line_code: this.syncStatus,
                    }
                )
                .then(() => {
                    this.$message.success('发送成功');
                    this.getInit();
                    this.isLoading = false;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                    this.isLoading = false;
                });
        },
        // 同步选中机组
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 同步到其他生产线
        saveLineSync() {
            this.dialogVisible3 = true;
        },
        checkSelectable(row) {
            return row.line_code !== this.mixstationChecked;
        },
        // 确定同步到机组
        determineSync() {
            this.dialogVisible3 = false;
            const obj = {
                product_formula_number: '',
                syncTargetLineInputs: [],
            };

            const tableData = this.productFormulaLine.filter(item => {
                return item.line_code === this.mixstationChecked;
            });

            if (tableData.length > 0) {
                if (tableData[0].productFormulaType.length > 0) {
                    const _tableData = tableData[0].productFormulaType.filter(item => {
                        return item.formula_type === this.formulaTypeChecked;
                    });
                    if (_tableData.length > 0) {
                        obj.product_formula_number = _tableData[0].product_formula_number;
                    } else {
                        obj.product_formula_number = '';
                    }
                } else {
                    obj.product_formula_number = '';
                }
            } else {
                obj.product_formula_number = '';
            }

            this.multipleSelection.map(item => {
                obj.syncTargetLineInputs.push({
                    line_code: item.line_code,
                    line_name: item.line_name,
                });
            });

            this.$axios
                .post('/interfaceApi/production/productformula/save_line/sync',
                    obj
                )
                .then(() => {
                    this.getInit();
                    this.$message.success('同步成功');
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 切换生产机组
        mixstationChange() {
            const tableData = this.productFormulaLine.filter(item => {
                return item.line_code === this.mixstationChecked;
            });
            if (tableData.length > 0) {
                if (tableData[0].productFormulaType.length > 0) {
                    const _tableData = tableData[0].productFormulaType.filter(item => {
                        return item.formula_type === this.formulaTypeChecked;
                    });
                    if (_tableData.length > 0) {
                        this.tableData = _tableData[0].productFormulaEntityOutputs;
                        this.productFormulaCount = _tableData[0];
                    } else {
                        this.tableData = [];
                        this.productFormulaCount = {};
                    }
                } else {
                    this.tableData = [];
                    this.productFormulaCount = {};
                }
            } else {
                this.tableData = [];
                this.productFormulaCount = {};
            }
        },
        // 切换 配比类型
        formulaTypeChange() {
            const tableData = this.productFormulaLine.filter(item => {
                return item.line_code === this.mixstationChecked;
            });
            if (tableData.length > 0) {
                if (tableData[0].productFormulaType.length > 0) {
                    const _tableData = tableData[0].productFormulaType.filter(item => {
                        return item.formula_type === this.formulaTypeChecked;
                    });
                    if (_tableData.length > 0) {
                        this.tableData = _tableData[0].productFormulaEntityOutputs;
                        this.productFormulaCount = _tableData[0];
                    } else {
                        this.tableData = [];
                        this.productFormulaCount = {};
                    }
                } else {
                    this.tableData = [];
                    this.productFormulaCount = {};
                }
            } else {
                this.tableData = [];
                this.productFormulaCount = {};
            }
        },
        // 选择
        Choice() {
            this.$toast(
                {
                    title: true,
                    text: '选择实验配比',
                    type: 'eject',
                    t_url: 'tableList/index',
                    extr: {
                        // eslint-disable-next-line max-len
                        code: { TableCode: 'choose_standard_formula', QueryString: 'line_code=' + this.mixstationChecked + '&formula_type=' + this.formulaTypeChecked + '&strength_grade=' + this.productFormula.strength_grade },
                        sureCallback: this.additionalCallback,
                    },
                }
            );
        },
        additionalCallback(table) {
            table.product_formula_number = this.productFormulaCount.product_formula_number;
            this.productFormulaCount = table;
            this.$axios
                .get(
                    '/interfaceApi/production/standardformula/formulaentity/'
                    + table.line_experiment_formula_number
                )
                .then(res => {
                    const tableData = res;
                    let practical_volume_weight = 0;
                    tableData.forEach(item => {
                        item.product_quantity = item.experiment_quantity;
                        practical_volume_weight += item.product_quantity;
                    });
                    this.tableData = tableData;
                    this.$set(this.productFormulaCount, 'practical_volume_weight', practical_volume_weight);
                    // this.productFormulaCount.practical_volume_weight = practical_volume_weight;

                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存
        saveLine() {
            // const tableData = this.productFormulaLine.filter(item => {
            //     return item.line_code === this.mixstationChecked;
            // });

            // let _productFormulaLine = null;
            // if (tableData.length > 0) {
            //     if (tableData[0].productFormulaType.length > 0) {
            //         const _tableData = tableData[0].productFormulaType.filter(item => {
            //             return item.formula_type === this.formulaTypeChecked;
            //         });
            //         if (_tableData.length > 0) {
            //             _productFormulaLine = _tableData[0];
            //         } else {
            //             _productFormulaLine = {};
            //         }
            //     } else {
            //         _productFormulaLine = {};
            //     }
            // } else {
            //     _productFormulaLine = {};
            // }
            const productFormulaLine = {
                product_formula_number: this.productFormulaCount.product_formula_number || '', // 施工配比编号
                line_experiment_formula_number: this.productFormulaCount.line_experiment_formula_number || '', // 生产线实验配比编号
                task_number: this.extr.taskNumber, // 任务单号
                formula_type: this.formulaTypeChecked, // 配方类型
                line_code: this.mixstationChecked, // 生产线编码
                design_volume_weight: this.productFormulaCount.design_volume_weight || '', // 设计容重
                slump: this.productFormulaCount.slump || '', // 坍落度
                water_binder_ratio: this.productFormulaCount.water_binder_ratio || '', // 水胶比
                sand_rate: this.productFormulaCount.sand_rate || '', // 砂率
                max_particle_size: this.productFormulaCount.max_particle_size || '', // 最大粒径
                fineness_modulus: this.productFormulaCount.fineness_modulus || '', // 细度模数
                scbt: this.productFormulaCount.scbt || '', // 生产拌台
            };

            // 获取生产线名称 line_name
            productFormulaLine.line_name = this.mixstationlineData.filter(item => {
                return item.line_code === this.mixstationChecked;
            })[0].line_name;
            // 排序字段
            productFormulaLine.sort_num = this.mixstationlineData.filter(item => {
                return item.line_code === this.mixstationChecked;
            })[0].sort_num;

            // const productFormulaEntity = this.tableData;
            const productFormulaEntity = JSON.parse(
                JSON.stringify(this.tableData).replace(
                    /^version$/g,
                    'industry_silo_version'
                )
            );

            this.isLoading = true;
            this.$axios
                .post(
                    '/interfaceApi/production/productformula/save_line',
                    {
                        productFormulaLine: productFormulaLine,
                        productFormulaEntity: productFormulaEntity,
                    }
                )
                .then(res => {
                    this.$message.success('保存成功');
                    this.productFormulaCount.product_formula_number = res.product_formula_number;
                    this.getInit();
                    this.Table.getTableList();
                    this.isLoading = false;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                    this.isLoading = false;
                });
        },
        // 保存施工配比基本信息
        saveProductFormula() {
            this.isLoading = true;
            this.$axios
                .post(
                    '/interfaceApi/production/productformula/save',
                    this.productFormula
                )
                .then(() => {
                    this.$message.success('保存成功');
                    this.Table.getTableList();
                    this.isLoading = false;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                    this.isLoading = false;
                });
        },
        // 初始化页面数据
        getInit() {
            this.$axios
                .get(
                    '/interfaceApi/production/productformula/formulawithline/'
                    + this.extr.taskNumber
                )
                .then(res => {
                    this.productFormula = res.productFormula;
                    this.mixstationlineData = res.productFormulaLine;
                    this.productFormulaLine = res.productFormulaLine;
                    if (!this.mixstationChecked) {
                        this.mixstationChecked = this.mixstationlineData[0].line_code;
                        this.mixstationChange();
                    }
                    this.syncStatus = [];
                    res.productFormulaLine.forEach(item => {
                        if (item.sync_status) {
                            this.syncStatus.push(item.line_code);
                        }
                    });

                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 根据原材料子类material_child_code获取原材料规格select数据
        materialModelSelect(index, code) {
            if (code) {
                this.tableIndex = index;
                this.dialogVisible2 = true;
                this.$axios
                    .get(
                        '/interfaceApi/baseinfo/dictionary_config/getlist?parent_code=' + code
                    )
                    .then(res => {
                        this.materialModelSelectData = res;
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }

        },
        materialModelSelectChange() {
            if (this.materialModelValue) {
                this.dialogVisible2 = false;
                this.tableData[this.tableIndex].material_name = this.materialModelValue;
                const material_code = this.materialModelSelectData.filter(item => item.name === this.materialModelValue)[0].code;
                this.tableData[this.tableIndex].material_code = material_code;
            } else {
                this.$message.error('请至少选择一个');
            }

        },
        materialChildSelectChange() {
            if (this.materialChildValue) {
                this.dialogVisible1 = false;
                if (this.tableData[this.tableIndex].stock_category_dictcode === '203') {
                    // this.tableData[this.tableIndex].material_child_name = this.materialChildValue;
                    // const material_child_dictcode = this.materialChildSelectData.filter(
                    //     item => item.material_name === this.materialChildValue)[0].material_code;
                    // this.tableData[this.tableIndex].material_child_dictcode = material_child_dictcode;
                    this.tableData[this.tableIndex].material_name = this.materialChildValue;
                    const material_child_dictcode = this.materialChildSelectData.filter(
                        item => item.material_name === this.materialChildValue)[0].material_code;
                    this.tableData[this.tableIndex].material_code = material_child_dictcode;
                } else if (this.tableData[this.tableIndex].stock_category_dictcode === '204') {
                    this.tableData[this.tableIndex].material_name = this.materialChildValue;
                    const material_child_dictcode = this.materialChildSelectData.filter(
                        item => item.material_name === this.materialChildValue)[0].material_code;
                    this.tableData[this.tableIndex].material_code = material_child_dictcode;
                }
            } else {
                this.$message.error('请至少选择一个');
            }
        },
        // 根据料场类型stock_code获取原材料籽料select数据
        materialChildSelect(index, code) {
            this.tableIndex = index;
            this.dialogVisible1 = true;
            this.$axios
                .get(
                    '/interfaceApi/Procurement/stock/materials/stock_code/'
                    + code
                )
                .then(res => {
                    this.materialChildSelectData = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });

        },
        cellStyle({ columnIndex }) {
            if (columnIndex === 1) {
                return {
                    color: '#317FC7',
                    textdecoration: 'underline',
                };
            }
            return false;
        },
    },
};
</script>

<style lang="stylus">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}
    .construction_matching
        // -webkit-backface-visibility hidden
        width 100%
        height 100%
        background #e0e5eb
        position relative
        .maske
            width 100%
            height 100%
            position fixed
            left 0
            top 0
            background rgba(0,0,0,0.5)
            z-index 999
        .dialog-wrapper
            position fixed
            width 14rem
            height 100%
            background #fff
            left 1.5rem
            top 0
            z-index 1000
            display flex
            flex-direction column
            overflow hidden
            .dialog-header
                height 0.6rem
                background #4f67ad
                color #fff
                display flex
                h4
                    text-align center
                    font-size 0.24rem
                    line-height 0.6rem
                    flex 1
                span
                    display inline-block
                    width 0.6rem
                    height 0.6rem
                    line-height 0.6rem
                    text-align center
            .dialog-box
                flex 1
                padding 0.1rem
                .btn-wrapper
                    width 100%
                    height 0.4rem
                    // margin 0.2rem 0
                    button
                        background #2978ff
                        color #fff
                        padding 0 0.2rem
                        border none
                        border-radius: 2px;
                        display block
                        height 0.3rem
                        line-height 0.3rem
        .el-dialog
            height 60% !important
            .el-table__row
                td
                    padding 0
                height 0.34rem
            .el-button
                margin 0 0.4rem
        .el-dialog__wrapper
            height 100% !important
        .el-container
            height 100%
            width 100%
        .el-aside
            width 5.4rem !important
            height 100%
            background #ffffff
            padding 0.2rem
            position relative
            .send_out
                width 0.6rem
                height 0.34rem
                background rgba(21,119,210,1)
                border-radius: 2px;
                color #ffffff
                text-align center
                line-height 0.34rem
                position absolute
                right 0.2rem
                top 0.2rem
                font-size 0.16rem
                cursor:pointer
            .el-table
                width 5rem
                font-size 0.16rem
                .el-table__header-wrapper
                    width 5rem
                    .el-table__header
                        width 5rem !important
                        table-layout inherit
                        th
                            font-size 0.16rem
                            .cell
                                padding 0
                                text-align center
                .el-table__body-wrapper
                    width 5rem
                    .el-table__body
                        width 5rem !important
                        table-layout inherit
                        .el-table__row
                            td
                                height 0.34rem
                                padding 0
                                line-height 0.34rem
                                .cell
                                    padding 0
                                    text-align center
                                    .sync_status
                                        display none
                                        opacity 0
                                        cursor:pointer
                                        &:checked+label>i
                                            color #1577D2
                                            font-weight 800
                                            font-size 0.22rem
                                        &:checked+label>i::before
                                            content: "\e6c1";
                                    label
                                        width 100%
                                        height 100%
                                        display block
                                        cursor:pointer
            .content_rigth
                margin-top 0.2rem
                ul
                    li
                        width 2.4rem
                        height 0.56rem
                        border 1px solid rgba(215,215,215,1)
                        margin-bottom 0.2rem
                        float left
                        margin-right 0.2rem
                        &:nth-child(even)
                            margin-right 0
                        span
                            font-size 0.18rem
                            line-height 0.56rem !important
                            display block
                            height 0.56rem
                            width 1rem
                            float left
                            text-align center
                        p
                            float right
                            line-height 0.56rem !important
                            font-size 0.20rem
                            margin-right 0.1rem
                            i
                                font-size 0.16rem
                                color rgba(215,215,215,1)

                    .sp1
                        span
                            background #5588f12e
                            color #5588F1
                        p
                            color #5588F1
                    .sp2
                        span
                            background #eb656f7a
                            color #EB656F
                        p
                            color #5588F1
                    .sp3
                        span
                            background #21bdcc75
                            color #21BDCC
                        p
                            color #21BDCC
                    .sp4
                        span
                            background #fa843657
                            color #FA8436
                        p
                            color #FA8436
                    .sp5
                        span
                            background #8b6bee63
                            color #8B6BEE
                        p
                            color #8B6BEE
        .el-header
            padding 0.2rem 0.2rem 0.1rem 0.2rem
            width 11.4rem
            height 2.5rem !important
            background #ffffff
            margin-bottom 0.2rem
            &.header_wrapper
                position relative
                .save
                    width 0.6rem
                    height 0.34rem
                    background rgba(21,119,210,1)
                    border-radius: 2px;
                    color #ffffff
                    text-align center
                    line-height 0.34rem
                    position absolute
                    right 0.2rem
                    top 0.2rem
                    font-size 0.16rem
                    cursor:pointer
            table
                height calc(100% - 0.4rem)
                th
                    font-size 0.14rem
                    color #333
                    font-weight 400
                    text-align left
                    padding-left 0.1rem
                td
                    .el-input
                        width 2rem
                    .el-select
                        width 2rem
                    .el-radio
                        &::after
                            transition none !important
                    .el-input__inner
                        color #000
        .el-main
            width 11.4rem
            height 100%
            background #ffffff
            padding 0.2rem
            overflow hidden
            .production_wrapper
                .production_span
                    font-size 0.16rem
                    float left
                    line-height 0.34rem
                .radio_list
                    .red-class
                        border 0.01rem solid red
                        .el-radio__label
                            color red
                    .el-radio
                        // width 0.6rem
                        height 0.34rem
                        // padding 0.1rem
                        margin-right 0.1rem
                        margin-right: 0.1rem !important;
                        line-height 0.1rem;
                        .el-radio__input{
                            display none
                        }
                        .el-radio__label{
                            padding 0;
                            width:100%;
                            height:100%;
                            line-height 0.1rem;
                        }

                    .is-bordered
                        padding 0.1rem
            .main_header
                // display flex
                margin 0.2rem 0
                overflow hidden
                .matching_type_wrapper
                    width 60%
                    float left
                    span
                        font-size 0.16rem
                        float left
                        line-height 0.5rem
                    .el-radio__input
                        margin-top 0.14rem
                .btn_wrapper
                    float right
                    .el-button
                        // width 0.6rem
                        height 0.34rem
                        padding 0rem 0.1rem
                        font-size 0.16rem
                        background: #1577d2;
            .content_left
                width 11rem
                .active-red
                    color red
                .el-table
                    width 11rem
                    font-size 0.16rem
                    .el-table__header-wrapper
                        width 11rem
                        .el-table__header
                            width 11rem !important
                            table-layout inherit
                            th
                                font-size 0.16rem
                                .cell
                                    padding 0
                                    text-align center
                    .el-table__body-wrapper
                        width 11rem
                        .el-table__body
                            width 11rem !important
                            table-layout inherit
                            .el-table__row
                                td
                                    height 0.34rem
                                    padding 0
                                    .cell
                                        padding 0
                                        text-align center
                        .el-table__empty-block
                            width 11rem !important
</style>
